import { useRouter } from 'next/router';
import { useBI } from '../../hooks/useBI';
import {
  clickTheSeeAllResultsLink,
  askingAQuestion,
  clickOnAnswer,
  hcSearchDropdownSourceSectionOnView,
} from '@wix/bi-logger-customer-care-data/v2';
import { useCallback } from 'react';
import {
  mapArticleTypeToItemType,
  mapResourceTypeToItemType,
  mapPageTypeToSourceName,
  cleanGeneratedAnswer,
} from '../../utils/bi';
import {
  ArticleSearchResultDocumentType,
  ArticleSearchResultItem,
  ArticleSourceType,
  PageType,
  SiteMetaData,
} from '../../types';
import { ArticleType } from '@wix/answers-api';
import { BI, EXPERIMENTS, GEN_ANSWER_RELATED_ARTICLES_COUNT } from '../../constants';
import { countWords } from '../../utils/text';
import { useExperiments } from '@wix/fe-essentials-standalone';

export type ClickOnResultBI = {
  url: string;
  index: number;
  resultSourceType?: ArticleType;
  docType: ArticleSearchResultDocumentType;
  inputText: string;
  searchSessionId: string;
  pageType: PageType;
  clickedItemId?: string;
  itemType?: string;
  clickedItemType?: string;
  id?: string;
  genAnswerBasedOnArticles?: string;
  genAnswerWordsCount?: number;
  genAnswer?: string;
  searchMethod?: string;
  selfServiceSessionId?: string;
  searchResultSelection?: string;
};

export type SearchQuestionBIParams = {
  results: ArticleSearchResultItem[] | ArticleSearchResultItem[],
  pageType: PageType,
  inputText: string,
  searchSessionId: string,
  searchMethod?: string,
  searchVersion?: string,
  inputType?: string,
  genAnswerBasedOnArticles?: string,
  genAnswerWordsCount?: number,
  isContactSupport?: boolean;
  isProhibited?: boolean;
  isGenerateAnswerFailed?: boolean;
};

export const useSearchBarBI = () => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const { experiments } = useExperiments({ readOnly: true });

  const isSitePickerEnabled = experiments.enabled(EXPERIMENTS.SPECS.SITE_PICKER);

  const clickAllResultsBI = useCallback(
    async (
      inputText: string,
      pageType: PageType,
      searchSessionId: string,
      clear: () => void,
      itemId?: string,
      itemType?: string,
      resourceType?: ArticleSourceType | string
    ) => {
      const typeTrigger =
        resourceType === 'general'
          ? 'general'
          : mapResourceTypeToItemType(resourceType as ArticleSourceType);
      await sendBIEvent(
        clickTheSeeAllResultsLink({
          source_name: mapPageTypeToSourceName(pageType),
          item_id: itemId,
          item_type: itemType,
          kb_lang: locale as string,
          question: inputText,
          search_session_Id: searchSessionId,
          source_type_trigger: typeTrigger,
        })
      );
      clear();
    },
    [sendBIEvent]
  );

  const clickOnResultBI = useCallback(
    async (params: ClickOnResultBI) => {
      const itemTypeMigration = params.clickedItemType ? {
        clicked_item_type: params.clickedItemType
      } : {};

      await sendBIEvent(
        clickOnAnswer({
          question: params.inputText,
          source_name: mapPageTypeToSourceName(params.pageType),
          item_id: params.clickedItemId,
          item_type: params.docType === ArticleSearchResultDocumentType.WIX_DOCS_ARTICLE ? BI.CLICKED_ITEM_TYPES.WIX_DOCS : mapArticleTypeToItemType(params.resultSourceType as ArticleType),
          kb_lang: locale as string,
          clicked_url: params.url,
          clicked_item_id: params.clickedItemId,
          clicked_item_order: `${params.index + 1}`,
          search_session_Id: params.searchSessionId,
          clicked_item_type: params.docType === ArticleSearchResultDocumentType.WIX_DOCS_ARTICLE ? BI.CLICKED_ITEM_TYPES.WIX_DOCS : mapArticleTypeToItemType(params.resultSourceType as ArticleType),
          search_result_selection: params.searchResultSelection ||
            BI.SEARCH_RESULT_SELECTION.SEARCH_BAR_DROPDOWN_LIST,
          self_service_session_id: params.selfServiceSessionId,
          search_method: params.searchMethod,
          summarized_answer_based_on: params.genAnswerBasedOnArticles,
          summarized_answer_words_count: params.genAnswerWordsCount,
          summarized_answer_text: params.genAnswer,
          ...itemTypeMigration
        })
      );
    },
    [locale, sendBIEvent]
  );
  const searchQuestionBI = useCallback(
    async (params: SearchQuestionBIParams, generatedAnswer?: string | null, relatedArticlesTitles?: string[], siteSelector?: SiteMetaData) => {
      const shouldCalculateGenerateAnswerParams = generatedAnswer && generatedAnswer?.length > 0;
      const getParsedArticles = () => {
        const searchResults = params.results;
        if (shouldCalculateGenerateAnswerParams && searchResults) {
          const topArticles = searchResults.slice(0, GEN_ANSWER_RELATED_ARTICLES_COUNT);
          const relatedArticles = topArticles
            .filter((article) => relatedArticlesTitles?.includes(article.title));
          const parsedRelatedArticles = relatedArticles.map((article) => ({
            article_id: article.id,
            output_distance: article.distance,
          }));
          return JSON.stringify(parsedRelatedArticles);
        } else { return undefined; }
      };

      const genAnswerWordCount = generatedAnswer && shouldCalculateGenerateAnswerParams ?
        countWords(generatedAnswer) : undefined;

      const getSiteSelectorProps = () => {
        if (!isSitePickerEnabled) {
          return {};
        }
        if (siteSelector) {
          return {
            context_msid: siteSelector.metaSiteId,
            context_editor_type: siteSelector.editor.editorType,
          };
        }
        return {
          context_msid: BI.CLICKED_ITEM_TYPES.ALL_SITES,
          context_editor_type: BI.CONTEXT_EDITOR_TYPE.ALL,
        };
      };

      await sendBIEvent(
        askingAQuestion({
          source_name: mapPageTypeToSourceName(params.pageType),
          kb_lang: locale as string,
          presented_content: JSON.stringify(
            (params.results as ArticleSearchResultItem[]).map((article, index) => ({
              order: index + 1,
              item_id: article.id,
              locale,
              type: mapArticleTypeToItemType(article.type, article.docType),
              output_distance: article.distance ? article.distance : null,
            }))
          ),
          question: params.inputText,
          search_session_Id: params.searchSessionId,
          search_method: params.searchMethod,
          search_version: params.searchVersion,
          input_type: params.inputType,
          summarized_answer_based_on: getParsedArticles(),
          summarized_answer_type: params.isContactSupport ? 'support request' : params.isProhibited ? 'prohibited' : params.isGenerateAnswerFailed ? 'answer generation failed' : generatedAnswer ? 'regular' : undefined,
          summarized_answer_words_count: genAnswerWordCount,
          summarized_answer_text: generatedAnswer ? cleanGeneratedAnswer(generatedAnswer) : undefined,
          is_support_request: params.isContactSupport,
          ...getSiteSelectorProps()
        })
      );
    },
    [isSitePickerEnabled, locale, sendBIEvent]
  );

  const viewResultsGroupBI = useCallback(
    async (
      resultSourceType: ArticleType,
      pageType: PageType,
      searchSessionId: string
    ) => {
      await sendBIEvent(
        hcSearchDropdownSourceSectionOnView({
          source_name: mapPageTypeToSourceName(pageType),
          kb_lang: locale as string,
          source_section_name: mapResourceTypeToItemType(resultSourceType),
          search_session_id: searchSessionId,
        })
      );
    },
    [sendBIEvent]
  );

  return {
    searchQuestionBI,
    clickAllResultsBI,
    clickOnResultBI,
    viewResultsGroupBI,
  };
};

