import React from 'react';
import {
  AnswersApi,
  Category,
  createAnswersApi,
  createApiTestKit,
} from '@wix/answers-api';
import { ANSWERS_API_BASE_URL } from '../constants';
import { createHttpService } from '../utils/answers';
import { PageTemplateData } from '../components/PageTemplate';
import { noop } from '@wix/answers-lib';
import { User } from '@wix/ambassador-identity-account-v2-account/build/cjs/types.impl';
import { PageType, SessionBI, SiteMetaData, SiteSelectorData } from '../types';
import moment from 'moment';

export const answersApi = createAnswersApi({
  baseUrl: ANSWERS_API_BASE_URL,
  http: createHttpService(),
  clientName: 'helpcenter',
});

export type ContextType = {
  pageTemplateData: PageTemplateData;
  setPageTemplateData: (pageTemplateData: PageTemplateData) => void;
  answersApi: AnswersApi;
  categoriesTree: Category[];
  isMobile: boolean;
  isTablet: boolean;
  isLoggedInUser: boolean;
  isWixStudioUser: boolean;
  isWixEmployee: boolean;
  experiments: { scopes: string[] };
  pageType: PageType;
  sessionBI: SessionBI;
  setSessionBI: (obj: SessionBI) => void;
  fetchSessionDebounceBI: (isLoggedInUser: boolean) => Promise<string | null>;
  user?: User;
  siteSelectorData: SiteSelectorData;
  setUserSelectedSite: (site?: SiteMetaData | null) => void;
  loggedInAccountId?: string | null;
  searchSessionId: string;
  setSearchSessionId: (newSearchSessionId: string) => void;
  showSearchModal: boolean;
  setShowSearchModal: (showSearchModal: boolean) => void;
};

export const Context = React.createContext<ContextType>({
  answersApi,
  categoriesTree: [],
  pageTemplateData: {},
  setPageTemplateData: noop,
  isMobile: false,
  isTablet: false,
  isLoggedInUser: false,
  isWixStudioUser: false,
  isWixEmployee: false,
  experiments: { scopes: [] },
  pageType: PageType.Homepage,
  sessionBI: {
    lastSessionDate: moment(),
    selfServiceSessionId: null,
  },
  setSessionBI: noop,
  fetchSessionDebounceBI: noop,
  user: undefined,
  siteSelectorData: {
    itemsCount: 0,
    sites: [],
    selectedSite: undefined,
  },
  setUserSelectedSite: noop,
  loggedInAccountId: undefined,
  searchSessionId: '',
  setSearchSessionId: noop,
  showSearchModal: false,
  setShowSearchModal: noop,
});

export const TestContextProvider = (props: {
  value: Partial<ContextType>;
  children: JSX.Element;
}) => (
  <Context.Provider
    value={{
      answersApi: createApiTestKit(),
      categoriesTree: [],
      pageTemplateData: {},
      setPageTemplateData: noop,
      isMobile: false,
      isTablet: false,
      isLoggedInUser: false,
      isWixStudioUser: false,
      experiments: { scopes: [] },
      isWixEmployee: false,
      pageType: PageType.Homepage,
      sessionBI: {
        lastSessionDate: moment(),
        selfServiceSessionId: null,
      },
      setSessionBI: noop,
      fetchSessionDebounceBI: noop,
      user: undefined,
      searchSessionId: '',
      setSearchSessionId: noop,
      showSearchModal: false,
      siteSelectorData: {
        itemsCount: 0,
        sites: [],
        selectedSite: undefined,
      },
      setUserSelectedSite: noop,
      setShowSearchModal: noop,
      ...props.value,
    }}
  >
    {props.children}
  </Context.Provider>
);
