import { useContext } from 'react';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS, LOCALES } from '../constants';
import { useRouter } from 'next/router';
import { Context } from '../context';

export const useIsGenAnswer = () => {
  const { experiments } = useExperiments({ readOnly: true });
  const { isMobile, isTablet } = useContext(Context);

  const { locale } = useRouter();
  const isGenAnswerEnabled =
    experiments.enabled(EXPERIMENTS.SPECS.NEXT_GEN_SEARCH) &&
    locale === LOCALES.EN &&
    !isMobile &&
    !isTablet;

  return isGenAnswerEnabled;
};
