import { useContext } from 'react';
import { Context } from '../context';
import { PageType } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { pushUrl } from '../components/InnerLink';
import { useRouter } from 'next/router';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS } from '../constants';

export type GoToSearchResultsPageParams = {
  term: string;
  previousPageType: PageType;
  overrideTerm?: string;
};

type QueryParams = {
  term: string;
  previousPageType: PageType;
  overrideTerm?: string;
};

export const useGoToSearchResultsPage = () => {
  const { locale } = useRouter();
  const { setSearchSessionId, siteSelectorData } = useContext(Context);
  const { experiments } = useExperiments({ readOnly: true });
  const isSitePickerEnabled = experiments.enabled(EXPERIMENTS.SPECS.SITE_PICKER);
  const editorType = isSitePickerEnabled ? siteSelectorData?.selectedSite?.editor?.editorType : '';

  const goToSearchResultsPage = async ({ term, previousPageType, overrideTerm = '' }: GoToSearchResultsPageParams) => {
    setSearchSessionId(uuidv4());

    const queryParams: QueryParams = { term: encodeURIComponent(term.trim()), previousPageType };

    if (overrideTerm) {
      Object.assign(queryParams, { inputType: 'suggested question', term: overrideTerm.trim() });
    }

    if (editorType) {
      Object.assign(queryParams, { editorType });
    }

    await pushUrl({
      pathname: `/${locale}/search`,
      query: queryParams,
    });
  };
  return { goToSearchResultsPage };
};
